import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Properties from './pages/Properties';
import PropertiesDetails from './pages/PropertiesDetails';
import Contact from './pages/Contact';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/properties' element={<Properties/>}></Route>
          <Route path='/propertydetails' element={<PropertiesDetails/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
