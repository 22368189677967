import React, { Fragment } from "react";

function Footer() {
    return (
        <Fragment>
            <footer>
                <div className="container">
                    <div className="col-lg-8">
                        <p>Copyright © 2024 Villa Agency Co., Ltd. All rights reserved.

                            Design: <a rel="nofollow" href="https://templatemo.com" target="_blank">TemplateMo</a></p>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}
export default Footer;